import { useEffect } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  FormLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller } from "react-hook-form";
import { baseTheme } from "../../themes/shipinsure";
import { GradientBox } from "./shared/GradientBox";
import { Menu } from "./shared/Menu";
import { useOnboarding } from "./OnboardingProvider";

const platforms = ["Shopify", "Magento", "Woocommerce", "Other"];

export function Information() {
  const { merchant, form } = useOnboarding();

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = form;

  useEffect(() => {
    if (!merchant) return;

    if (merchant && !isValid) {
      setValue("storeUrl", merchant.website_url);
      setValue("storeName", merchant.name);
    }
  }, [merchant]);

  return (
    <GradientBox>
      <Box display="flex" padding="14px" height="100%">
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Menu step={2} />
        </Box>
        <Box
          bgcolor="#fff"
          width="100%"
          borderRadius="24px"
          display="flex"
          flexDirection="column"
          sx={{ padding: { xs: "30px", md: "40px 69px" } }}
        >
          <Typography variant="h3" mb="32px" color={baseTheme.colors.textLight}>
            Welcome
            {merchant?.id && merchant?.full_name
              ? `, ${merchant.full_name}`
              : ""}
            !
          </Typography>
          <Grid
            container
            columnSpacing="60px"
            rowSpacing="32px"
            direction="row"
          >
            <Grid item xs={12}>
              <Controller
                name="storeName"
                control={control}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={errors.storeName ? true : false}
                    helperText={errors.storeName?.message}
                    label="Store Name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="storeUrl"
                control={control}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.storeUrl ? true : false}
                    helperText={errors.storeUrl?.message}
                    fullWidth
                    label="Store URL"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="platform"
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      focused={false}
                      sx={{
                        fontSize: "11px",
                        paddingLeft: "16px",
                        color: "#444056",
                        fontWeight: 700,
                      }}
                    >
                      Platform
                    </FormLabel>
                    <RadioGroup {...field}>
                      {platforms.map((platform, i) => (
                        <FormControlLabel
                          key={i}
                          value={platform}
                          control={<Radio />}
                          label={platform}
                          labelPlacement="start"
                          sx={{ justifyContent: "space-between" }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            width="50%"
            sx={{ margin: { xs: "50px auto", md: "auto auto 0" } }}
          >
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Next
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </GradientBox>
  );
}
