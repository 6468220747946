import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  statusStepper: {
    marginTop: "30px",
    "& .MuiStepIcon-root": {
      width: "35px",
      height: "35px",
    },
    "& .MuiStepConnector-root": {
      top: "15px",
      left: "calc(-50% + 17px)",
      right: "calc(50% + 17px)",
    },
    "& .Mui-completed .MuiStepConnector-line": {
      borderColor: "rgba(6, 0, 30, 0.75)",
    },
    "& .MuiStepLabel-label": {
      marginTop: "-56px",
    },
  },
  historyStepper: {
    "& .MuiStepConnector-line": {
      borderLeftWidth: "2px",
    },
    "& .MuiStepLabel-label": {
      fontWeight: "bold",
    },
  },
});

export const shipmentStatuses = {
  delivered: "Delivered",
  waiting_for_pickup: "Waiting for pickup",
  failure: "Failed",
  cancelled: "Cancelled",
  in_transit: "In transit",
  return_to_sender: "Returned to sender",
  available_for_pickup: "Available for pickup",
  unknown_carrier: "Unknown carrier",
  out_for_delivery: "Out for delivery",
  error: "Error",
  alert: "Alert",
};
