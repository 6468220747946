import { Box, Button, Divider, Typography } from "@mui/material";
import { useClaims } from "../ClaimsProvider";
import { LoadingButton } from "@mui/lab";
import { createTranslator } from "next-intl";
import content from "../../../content/claims.json";

export function ReviewClaim() {
  const {
    claimsForm,
    order,
    setActiveStep,
    onClaimSubmit,
    isSubmitting,
    setIsSubmitting,
    locale,
  } = useClaims();
  // any form item that has a reason is claimable
  const claimableItems = claimsForm
    .getValues()
    .items.filter((d) => d.reason.length > 1);
  const items = claimableItems.map((claimableItem) => ({
    ...claimableItem,
    lineItem: order.line_items.find((d) => d.id === claimableItem.itemId),
  }));
  const t = createTranslator({
    messages: content[locale],
  });

  return (
    <>
      {t.rich("review.body", {
        header: (chunk) => <Typography variant="h3">{chunk}</Typography>,
        text: (chunk) => <Typography paragraph>{chunk}</Typography>,
      })}
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Typography fontWeight="bold" fontSize="20px">
        {t("review.items")}
      </Typography>
      <Box>
        {items.map((item, index) => (
          <Box
            key={index}
            py="30px"
            borderBottom="1px solid #E0E3FF"
            sx={{
              "&:last-child": {
                border: 0,
              },
            }}
          >
            <Typography>{item.lineItem.title}</Typography>
            <Typography fontSize="13px" color="#423E56">
              ${item.lineItem.price}
            </Typography>
            <Typography mt="10px">
              <Typography component="span" fontWeight="bold">
                {t("review.reason")}
              </Typography>{" "}
              {item.reason}
            </Typography>
            <Typography mt="10px">
              <Typography component="span" fontWeight="bold">
                {t("review.description")}
              </Typography>{" "}
              {item.details}
            </Typography>
            <Typography mt="10px">
              <Typography component="span" fontWeight="bold">
                {t("review.resolution")}
              </Typography>{" "}
              {item.resolution}
            </Typography>
            {!order.shipping_protection_paid && order.allow_returns && (
              <Typography mt="10px">
                <Typography component="span" fontWeight="bold">
                  Return Label:
                </Typography>{" "}
                The merchant will not provide a return label, ShipInsure will
                purchase and send the return label to you.
              </Typography>
            )}
          </Box>
        ))}
      </Box>
      <Box display="flex" mt="20px">
        <Box flex="0 0 20%" mr="20px">
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setIsSubmitting(false);
              setActiveStep(1);
            }}
          >
            {t("review.form.back")}
          </Button>
        </Box>
        <Box flex="1">
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            fullWidth
            onClick={() => {
              setIsSubmitting(false);
              onClaimSubmit(items);
            }}
          >
            {t("review.form.submit")}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}
