import { Box, GlobalStyles } from "@mui/material";
import { Header } from "../shared/Header";
import { useTenantTheme } from "../themes/TenantThemeProvider";
import background from "../assets/images/bg-pattern.png";

// https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
// overwrites base body styles to control site background color from within components
const inlineStyles = (backgroundColor, isPrimaryTheme) => (
  <GlobalStyles
    styles={{
      body: {
        background: backgroundColor,
        ...(isPrimaryTheme ? { backgroundImage: `url(${background})` } : {}),
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center fixed",
        backgroundSize: "cover",
      },
    }}
  />
);

export function MainLayout(props) {
  const {
    siTheme: { backgroundColor, isPrimaryTheme },
  } = useTenantTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      maxWidth="800px"
      mb="32px"
      mx="auto"
      sx={{ paddingX: { xs: "16px", md: "0" } }}
    >
      {inlineStyles(backgroundColor, isPrimaryTheme)}
      <Header text={props.helpText} />
      {props.children}
    </Box>
  );
}
