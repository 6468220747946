// logic to filter the right issues for user problem, i.e.
// Return this item -> can_return/can_exhange
// Issue with delivery -> etc.
export function getIssueOptions(issueType, issues) {
  const returnIssues = issues.filter((d) => d.issue_type === "return");
  const insuranceIssues = issues.filter((d) => d.issue_type === "insurance");

  if (issueType === "return") {
    return returnIssues.filter((issue) => issue.is_enabled);
  } else {
    return insuranceIssues.filter((issue) => issue.is_enabled);
  }
}

// builds requested resolution outcomes and
// maps issue flags to resolution of what user can choose
export function getResolutionOptions(options, currentReason, can_store_credit) {
  const option = options.find((d) => d.issue_name === currentReason) ?? {};
  const keys = [
    "can_refund",
    "can_reship",
    "can_return",
    "can_exchange",
    can_store_credit ? "can_store_credit" : "",
  ];

  return Object.keys(option)
    .filter((key) => keys.includes(key) && option[key]?.length)
    .map((key) => option[key]);
}
