import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { createTranslator } from "next-intl";
import content from "../../content/claims.json";
import { MainLayout } from "../../layouts/MainLayout";
import { Loader } from "../../shared/Loader";
import { useTenantTheme } from "../../themes/TenantThemeProvider";
import { Card } from "../../shared/Card";
import { getClaim } from "../../api/getClaim";
import { ProgressBar } from "./components/ProgressBar";
import { identify, addEventProperties } from "../../helpers/heap";

export function Status() {
  const { isThemeLoading, isAppLoading, setIsAppLoading, locale } =
    useTenantTheme();
  const { claimNumber } = useParams();
  const [claim, setClaim] = useState();

  const t = createTranslator({
    messages: content[locale],
  });

  useEffect(() => {
    setIsAppLoading(true);

    fetchClaim();

    async function fetchClaim() {
      try {
        const response = await getClaim(claimNumber);

        if (response.data) {
          // identify order in Heap on lookup (claim_number -> ship_insure_id)
          identify(response.data.claim_number);
          addEventProperties({
            "Order Number": response.data.order_number,
          });

          setClaim({
            ...response.data,
            ...getClaimConfig(response.data.claim_status, locale),
          });
        }
      } finally {
        setIsAppLoading(false);
      }
    }
  }, [claimNumber, locale]);

  if (isThemeLoading || isAppLoading) {
    return <Loader />;
  }

  if (!claim) {
    return (
      <MainLayout>
        <Card title={t("status.notFound")} />
      </MainLayout>
    );
  }

  return (
    <MainLayout helpText={t("helpText")}>
      <Card title={claim.statusText}>
        <ProgressBar width={claim.progressWidth} />
        <Typography paragraph mb="25px">
          {claim.statusDescription}
        </Typography>
        <Typography variant="h6">{t("status.details")}</Typography>
        <Grid container rowSpacing="25px">
          <Grid item xs={6}>
            <Typography fontWeight="bold" fontSize="12px">
              {t("status.orderNumber")}
            </Typography>
            <Typography>#{claim.order_number}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight="bold" fontSize="12px">
              {t("status.orderDate")}
            </Typography>
            <Typography>
              {" "}
              {format(new Date(claim.order.created_at), "LLLL dd, yyyy")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight="bold" fontSize="12px">
              {t("status.claimNumber")}
            </Typography>
            <Typography>{claim.claim_number}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight="bold" fontSize="12px">
              {t("status.claimDate")}
            </Typography>
            <Typography>
              {" "}
              {format(new Date(claim.created_at), "LLLL dd, yyyy")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold" fontSize="12px">
              {t("status.reason")}
            </Typography>
            <Typography textTransform="capitalize">{claim.reason}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold" fontSize="12px">
              {t("status.resolution")}
            </Typography>
            <Typography textTransform="capitalize">
              {claim.preferred_resolution}
            </Typography>
          </Grid>
          {claim.additional_info && (
            <Grid item xs={12}>
              <Typography fontWeight="bold" fontSize="12px">
                {t("status.description")}
              </Typography>
              <Typography>{claim.additional_info}</Typography>
            </Grid>
          )}
          {claim.image_urls.length > 0 && (
            <Grid item xs={12}>
              <Typography fontWeight="bold" fontSize="12px">
                {t("status.photos")}
              </Typography>
              <Box display="flex" mt="10px">
                {claim.image_urls.map((url, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: "75px",
                      height: "75px",
                      overflow: "hidden",
                      mr: "10px",
                      mb: "10px",
                      "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    }}
                  >
                    <img src={`${url}`} loading="lazy" />
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Card>
    </MainLayout>
  );
}

function getClaimConfig(status, locale) {
  const t = createTranslator({
    messages: content[locale],
  });

  switch (status) {
    case "new": {
      return {
        statusTitle: t("status.new.title"),
        statusText: t("status.new.statusText"),
        statusDescription: t("status.new.statusDescription"),
        progressWidth: "33.33%",
      };
    }
    case "pending": {
      return {
        statusTitle: t("status.pending.title"),
        statusText: t("status.pending.statusText"),
        statusDescription: t("status.pending.statusDescription"),
        progressWidth: "66.66%",
      };
    }
    case "approved": {
      return {
        statusTitle: t("status.approved.title"),
        statusText: t("status.approved.statusText"),
        statusDescription: t("status.approved.statusDescription"),
        progressWidth: "100%",
      };
    }
    case "closed": {
      return {
        statusTitle: t("status.closed.title"),
        statusText: t("status.closed.statusText"),
        statusDescription: t("status.closed.statusDescription"),
        progressWidth: "100%",
      };
    }
    case "denied": {
      return {
        statusTitle: t("status.denied.title"),
        statusText: t("status.denied.statusText"),
        statusDescription: t("status.denied.statusDescription"),
        progressWidth: "100%",
      };
    }
    default: {
      return {};
    }
  }
}
