import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { createTranslator } from "next-intl";
import { Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import ImageDropUpload from "../../../shared/ImageDropUpload";
import { useClaims } from "../ClaimsProvider";
import { Overview } from "../components/Overview";
import { getIssueOptions, getResolutionOptions } from "../components/options";
import { ProductTitle } from "./components/ProductTitle";
import content from "../../../content/claims.json";
import { baseTheme } from "../../../themes/shipinsure";

export function ResolveAllClaim() {
  const { claimsForm, order, setActiveStep, setIsSelectAll, locale } =
    useClaims();
  const {
    setValue,
    getValues,
    register,
    reset,
    isSubmitting,
    control,
    watch,
    handleSubmit,
    formState: { isDirty, errors },
  } = claimsForm;

  const t = createTranslator({
    messages: content[locale],
  });

  const currentReason = watch("reason");
  const issueOptions = getIssueOptions("insurance", order.merchant_issues);
  const resolutionOptions = getResolutionOptions(issueOptions, currentReason, order.store_credits_enabled);

  return (
    <>
      <Overview />
      <form
        onSubmit={handleSubmit((values) => {
          // map all items to array items paylod to match
          // submit schema for all items
          const items = order.line_items.map((order, index) => ({
            lineItem: order,
            itemId: order.id,
            reason: values.reason,
            images: index === 0 ? values.images : [], // only add images to first item so we don't double upload
            details: values.details,
            resolution: values.resolution,
          }));

          setValue("items", items);
          setActiveStep(2);
        })}
      >
        <Box mt="32px" p="24px" bgcolor="#F4F4F9" borderRadius="8px">
          {order.line_items.map((item, index) => {
            return (
              <Box mb="18px" key={index}>
                <Grid container>
                  <ProductTitle item={item} title={t("resolve.variant")} />
                </Grid>
              </Box>
            );
          })}
          <Grid container rowSpacing={2} mt="10px">
            <Grid item xs={12}>
              <Controller
                name="reason"
                defaultValue=""
                control={control}
                rules={{
                  validate: (value) => (!value ? t("resolve.required") : true),
                }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>{t("resolve.form.issue")}</InputLabel>
                    <Select
                      label={t("resolve.form.issue")}
                      value=""
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        // reset following fields if user chooses
                        // to change their reason
                        setValue("images", []);
                        setValue("details", "");
                        setValue("resolution", "");
                      }}
                      error={!!errors.reason}
                    >
                      {issueOptions.map((issue, i) => (
                        <MenuItem key={i} value={issue.issue_name}>
                          {issue.issue_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {getErrorMessage(errors.reason)}
                  </FormControl>
                )}
              />
            </Grid>
            {currentReason === "Damaged" && (
              <Grid item xs={12}>
                <ImageDropUpload
                  images={getValues("images") ?? []}
                  onImageChange={(images) => {
                    setValue("images", images, {
                      shouldValidate: images.length ? true : false,
                    });
                  }}
                  text={t.rich("resolve.upload", {
                    text: (chunk) => <Typography>{chunk}</Typography>,
                    cta: (chunk) => (
                      <Typography
                        component="span"
                        color={baseTheme.colors.primary}
                      >
                        {chunk}
                      </Typography>
                    ),
                  })}
                />
                <input
                  type="hidden"
                  {...register("images", {
                    validate: (value) => {
                      return value.length === 0 ? t("resolve.required") : true;
                    },
                  })}
                />
                {getErrorMessage(errors.images)}
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="details"
                control={control}
                defaultValue=""
                rules={{
                  validate: (value) => (!value ? t("resolve.required") : true),
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    helperText={errors?.details?.message}
                    error={!!errors.details}
                    label={t("resolve.form.details")}
                    placeholder={t("resolve.form.details")}
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />
            </Grid>
            {currentReason && (
              <Grid item xs={12}>
                <Controller
                  name="resolution"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: (value) =>
                      !value ? t("resolve.required") : true,
                  }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>{t("resolve.form.resolution")}</InputLabel>
                      <Select
                        label={t("resolve.form.resolution")}
                        value=""
                        {...field}
                        error={!!errors.resolution}
                      >
                        {resolutionOptions.map((option, i) => (
                          <MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {getErrorMessage(errors.resolution)}
                    </FormControl>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box display="flex" mt="36px">
          <Box flex="0 0 20%" mr="20px">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                reset();
                setIsSelectAll(false);
                setActiveStep(0);
              }}
            >
              {t("resolve.form.back")}
            </Button>
          </Box>
          <Box flex="1">
            <LoadingButton
              disabled={!isDirty}
              loading={isSubmitting}
              variant="contained"
              fullWidth
              type="submit"
            >
              {t("resolve.form.submit")}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </>
  );
}

// helper to get helper text for field message
function getErrorMessage(error) {
  return error && <FormHelperText error>{error.message}</FormHelperText>;
}
