import { Box, Typography } from "@mui/material";
import { baseTheme } from "../themes/shipinsure";

export function Card(props) {
  return (
    <Box maxWidth="800px" width="100%">
      <Box
        sx={{
          padding: { xs: "32px", md: "64px" },
        }}
        boxShadow="0px 12px 34px -10px rgba(102, 108, 157, 0.2)"
        borderRadius="24px"
        backgroundColor={baseTheme.colors.neutral}
        mb={props.enableBrand ? "18px" : "50px"}
      >
        {props.title && <Typography variant="h3">{props.title}</Typography>}
        {props.children}
      </Box>
      {props.enableBrand && <Brand {...props} />}
    </Box>
  );
}

function Brand(props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" mb="50px">
      <Typography
        color="#B1BCCD"
        fontSize="11px"
        margin="0"
        mr="7px"
        fontWeight={700}
      >
        {props.poweredByText ?? "Powered by"}
      </Typography>
      <svg
        width="73"
        height="17"
        viewBox="0 0 73 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.1531 6.21268C24.9435 5.97145 24.6625 5.77686 24.3131 5.62729C23.9622 5.47773 23.5636 5.40375 23.1158 5.40375C22.4584 5.40375 21.9772 5.52919 21.6771 5.78168C21.3754 6.03417 21.2261 6.37511 21.2261 6.80772C21.2261 7.03447 21.2658 7.22103 21.3468 7.36577C21.4278 7.5105 21.5517 7.63755 21.72 7.7437C21.8883 7.84984 22.1027 7.9415 22.3663 8.02031C22.6283 8.09911 22.9379 8.1763 23.2952 8.2535C23.6938 8.33873 24.0527 8.43362 24.3719 8.54137C24.691 8.64751 24.961 8.78099 25.1865 8.9402C25.4104 9.09942 25.5819 9.2924 25.701 9.51916C25.8201 9.74592 25.8788 10.029 25.8788 10.3699C25.8788 10.7108 25.8121 11.018 25.6788 11.2737C25.5454 11.5294 25.3596 11.7417 25.1214 11.9122C24.8832 12.0826 24.5989 12.2081 24.2702 12.2901C23.9415 12.3721 23.5811 12.4123 23.1888 12.4123C22.0328 12.4123 21.0356 12.0473 20.1956 11.3171L20.5624 10.7108C20.6958 10.8524 20.8546 10.9875 21.0403 11.1145C21.2261 11.2416 21.4326 11.3541 21.6596 11.449C21.8867 11.5455 22.1297 11.6195 22.3901 11.6726C22.6489 11.7256 22.9221 11.753 23.2095 11.753C23.805 11.753 24.2687 11.6452 24.6005 11.4281C24.9324 11.2126 25.0992 10.8845 25.0992 10.4439C25.0992 10.2091 25.0515 10.0129 24.9578 9.85367C24.8625 9.69445 24.7212 9.55615 24.5323 9.43875C24.3433 9.32135 24.1083 9.22003 23.8288 9.1364C23.5493 9.05117 23.2238 8.96593 22.8522 8.8807C22.46 8.78903 22.117 8.69254 21.8232 8.59283C21.5294 8.49312 21.2801 8.36929 21.0769 8.22133C20.8736 8.07177 20.7212 7.89326 20.6195 7.68419C20.5179 7.47512 20.4671 7.21781 20.4671 6.91386C20.4671 6.56649 20.5322 6.2561 20.6608 5.98271C20.791 5.70931 20.9737 5.48255 21.2118 5.30243C21.45 5.12231 21.7295 4.98401 22.0519 4.88752C22.3742 4.79102 22.7315 4.74438 23.1237 4.74438C23.6208 4.74438 24.0638 4.81997 24.4529 4.97275C24.8419 5.12553 25.1928 5.33942 25.5088 5.61603L25.1516 6.21107L25.1531 6.21268Z"
          fill="#B1BCCD"
        />
        <path
          d="M31.6224 12.3496H30.9078V9.25384C30.9078 8.62342 30.8014 8.15061 30.587 7.83861C30.3727 7.52662 30.0598 7.37062 29.647 7.37062C29.4437 7.37062 29.2421 7.41083 29.0436 7.49285C28.8435 7.57487 28.6561 7.68583 28.4814 7.82735C28.3067 7.96888 28.1543 8.13613 28.0241 8.32751C27.8939 8.51889 27.7986 8.72795 27.7351 8.95471V12.348H27.0205V4.58521H27.7351V8.06376C27.9526 7.64563 28.2528 7.31594 28.6386 7.07471C29.0229 6.83348 29.4405 6.71287 29.8883 6.71287C30.2028 6.71287 30.4695 6.77076 30.6871 6.88816C30.9046 7.00556 31.0825 7.16799 31.2222 7.37706C31.362 7.58612 31.4636 7.83861 31.5271 8.13292C31.5906 8.42722 31.6224 8.75208 31.6224 9.10588V12.3496Z"
          fill="#B1BCCD"
        />
        <path
          d="M33.1453 5.64823V4.58521H33.8598V5.64823H33.1453ZM33.1453 12.3496V6.80775H33.8598V12.3496H33.1453Z"
          fill="#B1BCCD"
        />
        <path
          d="M38.2284 12.4557C37.7663 12.4557 37.3582 12.3367 37.0057 12.0987C36.6516 11.8607 36.3673 11.5615 36.1498 11.1997V14.6139H35.4352V6.80767H36.0751V7.88196C36.299 7.53458 36.5896 7.25315 36.9469 7.03604C37.3042 6.82054 37.6932 6.71118 38.1125 6.71118C38.4904 6.71118 38.8366 6.79159 39.1526 6.95081C39.467 7.11002 39.7401 7.3223 39.972 7.58926C40.2022 7.85462 40.3832 8.16179 40.5135 8.50916C40.6437 8.85653 40.7072 9.21516 40.7072 9.58344C40.7072 9.97424 40.6468 10.3425 40.5293 10.6899C40.4102 11.0373 40.2403 11.3428 40.0196 11.605C39.7989 11.8671 39.5369 12.0746 39.232 12.2273C38.9271 12.3801 38.5936 12.4557 38.2284 12.4557ZM38.0283 11.8172C38.3221 11.8172 38.5904 11.7529 38.8318 11.6259C39.0732 11.4988 39.2796 11.33 39.4511 11.1209C39.6226 10.9118 39.7544 10.6738 39.8449 10.4085C39.9354 10.1431 39.9815 9.8681 39.9815 9.58344C39.9815 9.28593 39.9291 9.00288 39.8243 8.7327C39.7195 8.46252 39.575 8.22611 39.3939 8.02026C39.2113 7.81441 38.997 7.65198 38.7476 7.53137C38.4983 7.41075 38.2316 7.35125 37.9441 7.35125C37.7615 7.35125 37.571 7.38824 37.3725 7.46222C37.1724 7.53619 36.9866 7.6359 36.8151 7.75973C36.6436 7.88357 36.4959 8.02991 36.3737 8.19556C36.2514 8.36281 36.1752 8.53811 36.1482 8.72144V10.4229C36.2323 10.6207 36.3387 10.8057 36.4689 10.9762C36.5992 11.1466 36.7452 11.293 36.9104 11.4168C37.0755 11.5406 37.2518 11.6387 37.4408 11.7095C37.6297 11.7803 37.825 11.8156 38.0283 11.8156V11.8172Z"
          fill="#B1BCCD"
        />
        <path
          d="M41.9044 12.3498V4.79761H43.3542V12.3498H41.9044Z"
          fill="#B1BCCD"
        />
        <path
          d="M49.8854 12.3497H48.4785V9.22334C48.4785 8.77626 48.4007 8.4498 48.2467 8.24555C48.0926 8.0397 47.8798 7.93678 47.6067 7.93678C47.467 7.93678 47.3225 7.96572 47.1764 8.02201C47.0287 8.0783 46.8905 8.15871 46.7619 8.26163C46.6317 8.36456 46.5158 8.48678 46.4094 8.62831C46.3046 8.76983 46.2268 8.92583 46.1791 9.0963V12.3513H44.7722V6.77726H46.0426V7.80973C46.2458 7.45592 46.5396 7.17931 46.9255 6.97989C47.3098 6.78208 47.7449 6.68237 48.2276 6.68237C48.5706 6.68237 48.8501 6.7467 49.0676 6.87375C49.2852 7.0008 49.4519 7.16805 49.571 7.3739C49.6901 7.57975 49.7727 7.81294 49.8171 8.07669C49.8632 8.33883 49.8854 8.60579 49.8854 8.87436V12.3529V12.3497Z"
          fill="#B1BCCD"
        />
        <path
          d="M53.1947 12.4557C52.7326 12.4557 52.2817 12.3818 51.8402 12.2322C51.3988 12.0826 51.0208 11.8703 50.7064 11.5937L51.232 10.6996C51.5687 10.9408 51.8958 11.1241 52.2134 11.248C52.5326 11.3718 52.8486 11.4345 53.1646 11.4345C53.444 11.4345 53.6648 11.3814 53.8267 11.2753C53.9871 11.1692 54.0681 11.0164 54.0681 10.8186C54.0681 10.6208 53.9728 10.4744 53.7839 10.3827C53.5949 10.2911 53.2868 10.1849 52.8597 10.0643C52.5024 9.96461 52.1975 9.86973 51.9466 9.77645C51.6941 9.68478 51.4909 9.58025 51.3368 9.46285C51.1828 9.34545 51.0701 9.21197 51.0002 9.05919C50.9303 8.90641 50.8954 8.72468 50.8954 8.51079C50.8954 8.22775 50.9494 7.97204 51.0589 7.74528C51.1669 7.51853 51.3194 7.32554 51.5163 7.16633C51.7116 7.00711 51.9418 6.88489 52.2039 6.79966C52.4659 6.71442 52.7517 6.67261 53.0598 6.67261C53.4726 6.67261 53.8601 6.73372 54.2206 6.85273C54.581 6.97334 54.9113 7.16794 55.213 7.43811L54.6461 8.30012C54.3666 8.08783 54.0951 7.93184 53.8315 7.83213C53.5695 7.73242 53.3075 7.68256 53.0486 7.68256C52.8105 7.68256 52.6104 7.73242 52.45 7.83213C52.2896 7.93184 52.2086 8.09105 52.2086 8.31137C52.2086 8.41108 52.2277 8.49149 52.2658 8.55582C52.3039 8.62015 52.3658 8.67644 52.45 8.72629C52.5342 8.77614 52.6437 8.82439 52.7803 8.86942C52.9168 8.91606 53.0868 8.96431 53.29 9.01255C53.6679 9.11226 53.9919 9.21036 54.2618 9.31007C54.5318 9.40978 54.7525 9.52235 54.924 9.65101C55.0955 9.77806 55.221 9.92602 55.302 10.0917C55.3829 10.2589 55.4226 10.4583 55.4226 10.6931C55.4226 11.2399 55.2226 11.6693 54.824 11.9861C54.4254 12.3013 53.8823 12.4589 53.1963 12.4589L53.1947 12.4557Z"
          fill="#B1BCCD"
        />
        <path
          d="M58.0142 12.4558C57.4473 12.4558 57.017 12.2709 56.7232 11.9026C56.4294 11.5343 56.2817 10.9875 56.2817 10.2638V6.77563H57.6887V9.95507C57.6887 10.8138 57.9936 11.2416 58.6017 11.2416C58.8749 11.2416 59.1385 11.158 59.3941 10.9924C59.6498 10.8251 59.8578 10.5726 60.0198 10.2317V6.77563H61.4267V10.7109C61.4267 10.8605 61.4537 10.9666 61.5061 11.0293C61.5585 11.0937 61.6443 11.1291 61.7633 11.1355V12.3481C61.6236 12.377 61.5061 12.3947 61.4108 12.4012C61.3155 12.4076 61.2298 12.4124 61.1536 12.4124C60.9011 12.4124 60.6962 12.3545 60.539 12.2371C60.3818 12.1197 60.2881 11.9589 60.2611 11.753L60.2294 11.306C59.9848 11.6887 59.6688 11.9766 59.2846 12.168C58.8987 12.3593 58.4763 12.4558 58.0142 12.4558Z"
          fill="#B1BCCD"
        />
        <path
          d="M66.1016 8.00903C65.6745 8.01547 65.2934 8.09909 64.9567 8.25831C64.6201 8.41752 64.3787 8.65714 64.2326 8.97557V12.348H62.8257V6.77393H64.1167V7.96561C64.2151 7.77423 64.3295 7.60216 64.4629 7.44938C64.5962 7.2966 64.7392 7.16472 64.8932 7.05054C65.0472 6.93797 65.2028 6.84951 65.3601 6.79001C65.5173 6.73051 65.6697 6.69995 65.8174 6.69995H65.9905C66.0286 6.69995 66.0651 6.70317 66.1 6.71121V8.00903H66.1016Z"
          fill="#B1BCCD"
        />
        <path
          d="M69.1679 12.4557C68.7265 12.4557 68.3279 12.3802 67.9706 12.2274C67.6133 12.0746 67.3084 11.8671 67.0575 11.605C66.805 11.3429 66.6113 11.0373 66.4747 10.6899C66.3382 10.3426 66.2699 9.97749 66.2699 9.59474C66.2699 9.21199 66.3366 8.8228 66.47 8.47221C66.6034 8.12162 66.7955 7.81284 67.048 7.54749C67.3005 7.28213 67.607 7.07146 67.9674 6.91546C68.3279 6.75947 68.7328 6.68066 69.1806 6.68066C69.6284 6.68066 70.0302 6.75947 70.3827 6.91546C70.7368 7.07146 71.0369 7.28053 71.2862 7.54266C71.534 7.8048 71.7245 8.11036 71.8531 8.45774C71.9833 8.80511 72.0469 9.16696 72.0469 9.54328C72.0469 9.63494 72.0453 9.7234 72.0421 9.80863C72.0389 9.89387 72.0294 9.96463 72.0151 10.0209H67.761C67.7816 10.2412 67.834 10.4358 67.9182 10.6063C68.0024 10.7768 68.1103 10.9215 68.2437 11.0421C68.3771 11.1627 68.528 11.2544 68.6947 11.3187C68.863 11.3831 69.0377 11.4152 69.2203 11.4152C69.4998 11.4152 69.765 11.3461 70.0127 11.2078C70.2604 11.0695 70.4303 10.8877 70.5224 10.6594L71.7309 11.0003C71.5276 11.4265 71.2037 11.7755 70.759 12.0473C70.3144 12.3207 69.784 12.4574 69.1679 12.4574V12.4557ZM70.5955 9.09459C70.5605 8.67645 70.4081 8.34194 70.1382 8.08946C69.8682 7.83857 69.5411 7.71153 69.1568 7.71153C68.9678 7.71153 68.7916 7.7453 68.6264 7.81284C68.4613 7.88039 68.3168 7.97366 68.1913 8.09428C68.0659 8.2149 67.9627 8.35963 67.8817 8.53011C67.8007 8.70058 67.7546 8.88874 67.7403 9.09459H70.5971H70.5955Z"
          fill="#B1BCCD"
        />
        <path
          d="M13.7072 3.95475L7.0775 0.823567C6.98381 0.778537 6.87424 0.778537 6.78055 0.823567L0.203258 3.92902C0.0793976 3.98692 0 4.11396 0 4.25066V12.7935C0 12.8626 0.0396988 12.9253 0.101629 12.9543L6.78055 16.108C6.87424 16.153 6.98381 16.153 7.0775 16.108L13.7564 12.9543C13.8184 12.9253 13.8581 12.8626 13.8581 12.7935V7.7646L15.6461 6.92029C15.7461 6.87365 15.7779 6.7466 15.7144 6.65494L13.9549 4.16221C13.8914 4.07215 13.8056 3.99978 13.7072 3.95314V3.95475ZM7.0775 1.28995L12.5273 3.86308C12.6623 3.92741 12.6623 4.12201 12.5273 4.18473L7.0775 6.75786C6.98381 6.80289 6.87424 6.80289 6.78055 6.75786L1.3307 4.18633C1.19573 4.12201 1.19573 3.92741 1.3307 3.86469L6.78055 1.28995C6.87424 1.24492 6.98381 1.24492 7.0775 1.28995ZM9.8977 11.367C9.77384 11.4249 9.69444 11.5519 9.69444 11.6886V14.4065L7.67457 15.3602C7.55865 15.4149 7.42526 15.3296 7.42526 15.1994V7.99779L8.82583 9.98071C8.87506 10.0499 8.96557 10.074 9.04338 10.0386L13.3658 7.99779V12.5587C13.3658 12.6278 13.3261 12.6905 13.2642 12.7195L11.6762 13.4689V10.8041C11.6762 10.6739 11.5428 10.5886 11.4269 10.6433"
          fill="#B1BCCD"
        />
      </svg>
    </Box>
  );
}
