import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { createTranslator } from "next-intl";
import { Button, Divider, Typography } from "@mui/material";
import { useClaims } from "../ClaimsProvider";
import content from "../../../content/claims.json";

export function ThankYou() {
  const { locale } = useClaims();
  const [searchParams] = useSearchParams();
  const shipInsureId = searchParams.get("shipInsureId");

  const t = createTranslator({
    messages: content[locale],
    locale,
  });

  return (
    <>
      {t.rich("thanks.body", {
        header: (chunk) => <Typography variant="h3">{chunk}</Typography>,
        text: (chunk) => <Typography paragraph>{chunk}</Typography>,
        hr: () => <Divider sx={{ mt: 4, mb: 4 }} />,
        bold: (chunk) => <b>{chunk}</b>,
      })}
      {t.rich("thanks.cta", {
        button: (chunk) => (
          <Button
            sx={{ marginTop: "20px" }}
            component={RouterLink}
            to={`/claims/${shipInsureId}`}
            variant="contained"
            fullWidth
          >
            {chunk}
          </Button>
        ),
      })}
    </>
  );
}
