import { CssBaseline, ThemeProvider, createTheme, darken } from "@mui/material";
import { createContext, useEffect, useState, useContext } from "react";
import { muiTheme } from "./mui-theme";
import { getMultitenant } from "../api/multitenant";
import { getSubdomain } from "../helpers/getSubdomain";
import { useLocation } from "react-router-dom";

const TenantThemeContext = createContext();

export function TenantThemeProvider(props) {
  const { pathname } = useLocation();
  const [locale, setLocale] = useState("en");
  const [theme, setTheme] = useState({
    whitelabel: null,
    muiTheme,
    siTheme: {
      backgroundColor: "#F7F8FF",
      primaryColor: "#6675FF",
      horizontalLogoUrl:
        "https://cdn.shipinsure.io/logos/shipinsure-horizontal-purple.svg",
      isPrimaryTheme: true,
    },
  });
  const [themeStatus, setThemeStatus] = useState({
    isLoading: true,
    isLoaded: false,
  });

  // this state gives every component that consumes this provider
  // to control its own top-level loading state; (Claims for example)
  const [isAppLoading, setIsAppLoading] = useState(false);

  useEffect(() => {
    // onboarding should be ShipInsure specific theme
    // so don't fetch theme in this scenario;
    // once theme is loaded, we don't expect to fetch again, so we introduce a theme is loaded state
    if (pathname.includes("/onboarding") || themeStatus.themeIsLoaded) {
      setThemeStatus({
        isLoading: false,
        isLoaded: true,
      });
      return;
    }

    const fetchTheme = async () => {
      try {
        const response = await getMultitenant({ subdomain: getSubdomain() });
        const {
          data: { custom_attributes },
        } = response;

        if (custom_attributes?.store_name) {
          document.title = `${custom_attributes.store_name} / ShipInsure`;
        }

        if (custom_attributes?.favicon_url) {
          const favicon = document.getElementById("favicon");
          favicon.href = custom_attributes?.favicon_url;
        }

        // set to locale only if it exists;
        // we could receive null as locale
        if (response.data.locale) {
          setLocale(response.data.locale);
        }

        if (!response.data.is_primary) {
          setTheme((prevState) => ({
            whitelabel: response.data,
            muiTheme: {
              ...prevState.muiTheme,
              palette: {
                ...prevState.muiTheme.palette,
                primary: {
                  ...prevState.muiTheme.palette.primary,
                  ...(response.data.primary_color
                    ? {
                        main: response.data.primary_color,
                        dark: darken(response.data.primary_color, 0.2),
                      }
                    : {}),
                },
              },
            },
            siTheme: {
              ...prevState.siTheme,
              primaryColor: response.data.primary_color,
              horizontalLogoUrl: response.data.custom_attributes?.main_logo_url,
              isPrimaryTheme: false,
            },
          }));
        }
      } finally {
        setThemeStatus({
          isLoading: false,
          isLoaded: true,
        });
      }
    };

    fetchTheme();
  }, [pathname]);

  return (
    <TenantThemeContext.Provider
      value={{
        whitelabel: theme.whitelabel,
        siTheme: theme.siTheme,
        isThemeLoading: themeStatus.isLoading,
        isAppLoading,
        setIsAppLoading,
        locale,
      }}
    >
      <CssBaseline />
      <ThemeProvider theme={createTheme(theme.muiTheme)}>
        {props.children}
      </ThemeProvider>
    </TenantThemeContext.Provider>
  );
}

export const useTenantTheme = () => useContext(TenantThemeContext);
