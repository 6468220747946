import { Grid, Typography } from "@mui/material";

export function ProductTitle(props) {
  return (
    <>
      <Grid item xs={12} md={6}>
        <Typography>{props.item.title}</Typography>
        {props.item.variant_title && (
          <Typography sx={{ fontSize: "13px" }}>
            <b>{props.title}</b> {props.item.variant_title}
          </Typography>
        )}
        <Typography fontSize="13px" color="#423E56">
          ${props.item.price}
        </Typography>
      </Grid>
    </>
  );
}
