import { Box, Button, ThemeProvider, createTheme } from "@mui/material";
import { useTenantTheme } from "../themes/TenantThemeProvider";

export function Header(props) {
  const { siTheme } = useTenantTheme();

  return (
    <Box display="flex" justifyContent="space-between" py="32px" width="100%">
      <img src={siTheme.horizontalLogoUrl} height="40" />
      <ThemeProvider theme={buttonTheme}>
        <Button
          variant="outlined"
          href="https://help.shipinsure.io/"
          target="_blank"
          sx={{ maxWidth: "300px", pl: 4, pr: 4 }}
        >
          {props.text ?? "Need Help"}
        </Button>
      </ThemeProvider>
    </Box>
  );
}

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#25344D",
      light: "#25344D",
      dark: "#25344D",
      contrastText: "#423D55",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "100px",
          textTransform: "none",
        },
      },
    },
  },
});
